<template>
  <article class="popular-route fb-item">
    <div class="popular-route__wrapper">
      <Column>
        <div class="popular-route__image-holder">
            <img class="popular-route__image"
                 :src="getImageUrl()"
                 alt=""/>
        </div>
        <Column class="popular-route__info-holder"
                justify="between">
          <div>
            <div class="popular-route__info-title">{{ params.title }}</div>
            <div class="popular-route__info-items">
              <p class="popular-route__info-type">Формат: {{ params.format }}</p>
              <p class="popular-route__info-type">
                Мест: {{ getSeatsNumber() }}
              </p>
              <p class="popular-route__info-type" v-if="params.age_restrictions">
                Возраст: {{ params.age_restrictions }}+
              </p>
            </div>
          </div>
          <Row class="popular-route__info-time-distance-rating" justify="between">
            <Button
              class="flashmob-button"
              color="main"
              @click.native="signUp(params.id)"
              v-if="!$flashmobs.signed_event_id && getSeatsNumber() > 0"
            >
              Записаться
            </Button>
            <Button
              class="flashmob-button"
              color="like-link"
              @click.native="signDown"
              v-if="params.id === $flashmobs.signed_event_id"
            >
              Отписаться
            </Button>
            <a href="#" class="flashmob-button__extra" @click="openExtra(params)">
              Подробнее
            </a>
          </Row>
        </Column>
      </Column>
    </div>
  </article>
</template>

<script>
export default {
  name: 'PopularRoute',
  props: {
    params: {
      type: Object,
      required: true,
    },
    object_type: {},
  },
  methods: {
    openExtra(params) {
      this.$store.commit('OPEN_POPUP', {
        width: this.$viewport.desktop ? 800 : '80%',
        height: '80vh',
        type: 'PopupFlashmobItem',
        horizontal: 'center',
        params,
      });
    },
    getSeatsNumber() {
      if (this.params.seats_number < 0) {
        return 0;
      }
      return this.$flashmobs.signed_event_id
        ? this.params.seats_number - 1
        : this.params.seats_number;
    },
    signUp(id) {
      this.$store.commit('OPEN_POPUP', {
        width: this.$viewport.desktop ? 800 : '100%',
        height: '100vh',
        type: 'PopupFlashmob',
        horizontal: 'right',
        event_id: id,
      });
    },
    signDown() {
      this.$store.dispatch('REMOVE_FLASHMOB_SIGN_UP');
    },
    getImageUrl() {
      return `https://act.tomsk.life/storage/${this.params.images[0]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .fb-item {
    min-height: 400px;

    .popular-route {
      &__info-items {
        padding: 7px 0;
      }

      &__info-holder {
        height: auto;
      }
    }

    .flashmob-button {
      &__extra {
        position: absolute;
        right: 2.2rem;
        bottom: 2.2rem;
        font-size: 14px;
        color: #333;
        text-decoration: underline;

        &:hover {
          color: #777;
        }
      }
    }
  }
</style>
